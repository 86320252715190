.block_banner {
	padding: 15px;
	text-align: center;
	border-top: 10px solid theme(primary, base);
	background-image: linear-gradient( 150deg, $gray-400 15%, $gray-100 45%, $gray-100 55%, $gray-200 85% );

	@include media-breakpoint-only(md) {
		.branding-logo {
			width: 240px; 
			height: 79px;
		}
		.branding-best {
			img { display: inline-block; }
		}
	}

	@include media-breakpoint-up(md) {
		padding: 30px 15px;
		.banner_branding {
			@include make-col(4);
			padding: 0;
			text-align: left;
			.branding-logo,
			.branding-best { margin: 0; }
		}
		.banner_contact {
			@include make-col(8);
			padding-right: 0;
			text-align: right;
			.contact-call {
				@include make-flex;
				margin-top: 20px;
				align-items: center;
				justify-content: flex-end;
			}
			.contact-note {
				display: inline-block;
				margin-right: 10px;
			}
		}
		.banner_nav {
			@include make-col(8);
			margin-left: auto;
			padding-right: 0;
			text-align: right;
			.banner-nav {
				margin-top: -55px;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.banner_branding {
			@include make-col(6);
			padding: 10px 0;
			@include make-flex;
			align-items: center;
			> a { display: inline-block; }
			.branding-best { margin-left: 15px; }
		}
		.banner_contact,
		.banner_nav { @include make-col(6); }
	}

	@include media-breakpoint-up(xl) {
		.branding-best {
			img { display: inline-block; }
		}
	}

}