.block_jumbo {
	padding: 0 0 30px;
	border-top: 5px solid $white;
	background-image: linear-gradient(
		-150deg,
		theme(primary, dark),
		theme(primary, light)
	);

	@include media-breakpoint-up(lg) {
		position: relative;
		padding-bottom: 0;
		.container {
			position: absolute;
			top: 15px;
			left: 0;
			right: 0;
			margin: 0 auto;
		}
		.jumbo_conversion {
			@include make-col(4);
			margin-left: auto;
		}
	}

	@include media-breakpoint-up(xl) {
		.container { top: 30px; }
	}

}

	.jumbo_marketing {
		margin-bottom: 30px;
		border-bottom: 5px solid $white;
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}
	}

	.jumbo_conversion {
		@include media-breakpoint-up(lg) {
			.form {
				padding: 15px;
				background-color: rgba(255, 255, 255, 0.75);
			}
		}
	}