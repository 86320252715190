.page_title {
	border-top: 5px solid $white;
	background-color: theme(primary, base);
	@include gdfancy-bg($color-primary);
	color: $white;

	@include media-breakpoint-up(md) { padding: 90px 15px 15px; }
	@include media-breakpoint-up(lg) { padding-top: 120px; }

}

	.page-title {
		margin: 0;
	}
