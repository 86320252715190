.call {
	@include font-size(17);
	line-height: 1em;
	letter-spacing: -0.0275em;
	.phone {
		display: inline-block;
		@include font-size(27);
		font-weight: $body-weight-bolder;
		line-height: 1em;
		letter-spacing: -0.0525em;
		&.company-phone { color: theme(secondary, base); }
		&.contact-phone { color: theme(secondary, base); }
	}

	.contact-note {
		display: block;
		font-weight: $body-weight-bold;
	}

}


