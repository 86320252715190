.banner-nav {

	.nav-item {
		&.active {
			.nav-link {
				letter-spacing: -0.0325em;
				background-color: transparent;
				color: theme(text, base);
			}
		}
	}

	.nav-link {
		padding: 12px 20px;
		@include font-size(14);
		font-weight: $body-weight-bolder;
		border-radius: 4px;
		background-color: theme(primary, base);
		color: $white;
		&:hover {
			background-color: theme(secondary, base);
		}
	}

	@include media-breakpoint-up(md) {
		.nav-item {
			display: inline-block;
			padding: 0 0 0 6px;
			&.nav-menu_lunch { margin-left: 0; }
		}
		.nav-link { padding: 10px 15px; }
	}

	@include media-breakpoint-up(lg) {
		margin-top: 0;
	}

}