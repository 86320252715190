$slides: 8;

.slider_jumbo {
    
	padding: 0;
    line-height: 0;

	.slick-prev,
    .slick-next {
        //display: none !important;
    }

        .slick-prev { background-image: url("/_/images/icons/slide-text-prev.png"); }
        .slick-next { background-image: url("/_/images/icons/slide-text-next.png"); }

    .slide_wrap {
        max-width: 1170px;
        margin: 0 auto;
    }

	.slide-placeholder {
        display: block;
        width: 100%;
	}

    .slide_caption {
        padding: 15px;
        line-height: 1em;
    }
        .caption-title { margin-bottom: 0; }

    @include media-breakpoint-up(md) {

        .slide-placeholder { opacity: 0; }

        .slide_wrap { background-size: cover; }
        
        .slide_caption {
            position: absolute;
            bottom: 0;
            width: 100%;
            background: linear-gradient(to right, rgba(black, 0.7) 30%, transparent 60%);
        }

        @while $slides > 0 {
            .slide_#{$slides} .slide_wrap {
                background-image: url("/_/images/slides/jumbo/lg/#{$slides}.jpg");
            }
            $slides: $slides - 1;
        }

    }

    @include media-breakpoint-up(lg) {
        
        .slide_wrap { position: relative; }
        
        .caption-title {
            @include font-size(19);
            .price {
                display: inline-block;
                margin: 0;
                float: right;
            }
        }

        .slide { background-size: cover; }

        @while $slides > 0 {
            .slide_#{$slides} {
                background-image: url("/_/images/slides/jumbo/bg/#{$slides}.jpg");
            }
            $slides: $slides - 1;
        }

    }

}