.block_services {
	background-color: $gray-200;
}

	.services-list {
		@include make-flex;
		justify-content: center;
		li {
			@include make-col(4);
			padding: 10px;
			@include font-size(13);
			font-weight: $body-weight-bolder;
			line-height: 1em;
			letter-spacing: -0.0375em;
			text-transform: uppercase;
			img {
				max-width: 80px;
				margin: 0 auto 5px;
			}
		}

		@include media-breakpoint-up(md) {
			li { @include make-col(2); }
		}

	}