%widget {
	@include card(15px, 6px, $white);
	border: 3px solid transparent;
	box-shadow: none;
}

@mixin widgetFill($color) {
	text-shadow: 0 1px 1px rgba(0,0,0,.4);
	@include gdfancy-bg($color);
	border-color: darken($color, 10%);
	color: $white;
}

@mixin widgetBordered($color) {
	background: $white;
	border-color: $color;
	.widget-title { color: $color; }
}

.widget {
	@extend %widget;
	> *:last-child { margin-bottom: 0; }
}

.widget.fill--primary { @include widgetFill(theme(primary, base)); }
.widget.fill--secondary { @include widgetFill($color-secondary); }
.widget.fill--highlight { @include widgetFill($color-highlight); }
.widget.fill--accent { @include widgetFill($color-accent); }
.widget.fill--success { @include widgetFill($color-success); }
.widget.fill--danger { @include widgetFill($color-danger); }
.widget.fill--warning { @include widgetFill($color-warning); }
.widget.fill--info { @include widgetFill($color-info); }
.widget.fill--text { @include widgetFill($color-text); }
.widget.fill--review { @include widgetFill($color-review); }
.widget.fill--orange { @include widgetFill($orange); }

.widget.border--primary { @include widgetBordered(theme(primary, base)); }
.widget.border--secondary { @include widgetBordered($color-secondary); }
.widget.border--highlight { @include widgetBordered($color-highlight); }
.widget.border--accent { @include widgetBordered($color-accent); }
.widget.border--success { @include widgetBordered($color-success); }
.widget.border--danger { @include widgetBordered($color-danger); }
.widget.border--warning { @include widgetBordered($color-warning); }
.widget.border--info { @include widgetBordered($color-info); }
.widget.border--text { @include widgetBordered($color-text); }
.widget.border--review { @include widgetBordered($color-review); }
.widget.border--orange { @include widgetBordered($orange); }

.widget--plain {
	padding: 0;
	background-color: transparent;
	color: theme(text, base);
	text-shadow: none;
}

	.widget-title {
		margin: 0 0 0.5em;
		font-size: 1.2rem;
		font-family: $font-family-sans-serif;
		font-weight: $body-weight-bolder;
		letter-spacing: -0.0425em;
		line-height: 1em;
	}

.widget-conversion {
	margin-bottom: 5px;
	padding: 15px;
	font-weight: $body-weight-bold;
	line-height: 1em;
	text-transform: uppercase;
	>:last-child { margin-bottom: 0; }
}