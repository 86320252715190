.form_quick {

	@include media-breakpoint-only(md) {
		@include make-flex;
		.form-title { @include make-col(12); }
		.quick_contact_info,
		.quick_contact_comments,
		.quick_contact_submission {
			@include make-col(6);
		}
		.quick_contact_info { padding-right: 7px; }
		.quick_contact_comments { padding-left: 7px; }
		.quick_contact_submission { margin-top: -27px; }
	}

}

.page_contact .form_quick {

	@include media-breakpoint-up(md) {
		@include make-flex;
		.form-title { @include make-col(12); }
		.quick_contact_info,
		.quick_contact_comments,
		.quick_contact_submission {
			@include make-col(6);
		}
		.quick_contact_info { padding-right: 7px; }
		.quick_contact_comments { padding-left: 7px; }
		.quick_contact_submission { margin-top: -27px; }
	}

}

 .sidebar .form_quick {
	padding: 15px;
	border: 1px solid #ccc;
	background-color: #f0f0f0;
	//.form-title { margin-bottom: 15px; }
} 