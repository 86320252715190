.coverage,
.block_coverage {

	font-family: $font-family-sans-serif;

	.coverage-title {
		margin-top: 0;
		@include font-size(20);
	}

	.coverage-county,
	.coverage-city {
		@include font-size(13);
		line-height: 1.325em;
	}

	&.block {

		padding: 30px 15px;
		border-top: 5px solid $white;
		background-color: theme(primary, dark);
		color: $white;

		.wrap > *:last-child { margin-bottom: 0; }

		@include media-breakpoint-up(lg) {
			.wrap { @include make-col(10); text-align: center; }
		}

	}

}