body {

	min-width: 360px;

	@include media-breakpoint-up(md) {

		height: 100%;
		padding-bottom: 131px;

		.site_info {
			position: absolute;
			bottom: 0;
			width: 100%;
		}

	}

	@include media-breakpoint-up(lg) {
		background-size: 350px, auto;
		background-position: -55px -25px, left top;
	}

}

#directions,
#hours {
	outline: none !important;
	box-shadow: none !important;
}