// 240x79
// 270x89
// 360x119
// 480x158
// 640x211
// 980x323

.branding-logo {
	display: block;
	margin: 0 auto 15px;
	width: 270px;
	height: 89px;
	text-indent: -9999px;
	background-image: url("/_/images/layout/logo.png");
	background-size: cover;
}

	.branding-best {
		display: inline-block;
		margin-bottom: 15px;
		@include font-size(13);
		line-height: 1em;
		letter-spacing: -0.0275em;
		text-decoration: none;
		color: theme(text, base);
		img {
			display: block;
			width: 100%;
			max-width: 100px;
			vertical-align: middle;
		}
	}

	.logo-footer {
		display: block;
		max-width: 200px;
		margin: 0 auto;
	}